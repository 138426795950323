<template>
  <div class="top__bar">
    <a href="https://heretique.fr" target="_blank" class="top__bar-left">
      <p class="top__bar-text">
        Un projet <span class="text__strong">hérétique</span>
      </p>
    </a>
    <div class="top__bar-center">
      <p class="top__bar-text">
        Pour emprunter un livre&nbsp;
        <span class="text__strong"
          ><fa icon="arrow-right" />&nbsp;<a
            class="top__bar-link"
            href="mailto:bonjour@heretique.fr"
          >
            bonjour@heretique.fr</a
          >
        </span>
      </p>
    </div>
    <a @click="showModalAbout = true" class="top__bar-right" href="#"
      ><p class="top__bar-text">En savoir plus</p></a
    >
  </div>
  <div class="popup__about" v-if="showModalAbout">
    <slot>
      <fa
        @click="showModalAbout = false"
        class="popup__about-icon"
        icon="times"
      />
      <p class="popup__about-text">
        Labiblio.tech est la bibliothèque ouverte d’<a
          href="https://heretique.fr"
          target="_blank"
          class="popup__link"
          >hérétique.</a
        >
        Elle est localisée à Paris.
        <br />
        <br />Nous avons rassemblé une modeste collection de livres qui traitent
        du numérique, de ses obsessions, de ses critiques et de ses alternatives
        possibles. Labiblio.tech est un dispositif simple qui part d’une envie
        toute aussi simple : permettre à tous d’approfondir les pensées qui nous
        animent et de découvrir les auteurs qui nous inspirent.<br />
        <br />Un livre vous parle ou vous donne envie ? Envoyez nous un mail et
        prenons rendez-vous pour vous le transmettre, peut-être autour d’un
        café. Pas d’automatisation, pas de données récoltées, pas d’étoiles,
        pas de livraison en 24h. Juste des humains qui partagent ce qui mérite
        de l’être. <br />
        <br />
        Pour l’instant, les livres sont classés selon trois catégories :
        <br />&nbsp;<strong>• Orthodoxie :</strong> les livres à la base des
        idéologies de la Silicon Valley <br />&nbsp;<strong>• Hérésie :</strong>
        des ouvrages critiques des modèles et des impacts du numérique
        <br />&nbsp;<strong>• Hétérodoxies :</strong> des œuvres ouvrant la voie
        vers des numériques alternatifs
        <br />
        <br />
        Labiblio.tech s’enrichira de nouveaux contenus et fonctionnalités avec
        le temps. Si vous voulez prêter à labiblio.tech des livres qui méritent
        d’être prêtés à leur tour, rien ne nous rendrait plus heureux.
        <br />Bonne lecture à toutes et tous !<br />
        <br />L’équipe d’<a
          href="https://heretique.fr"
          target="_blank"
          class="popup__link"
          >hérétique.</a
        >
        <br />labiblio.tech est l’oeuvre de Kevin Echraghi, Antoine Mestrallet,
        Marie Réal et Antoine Kurka.
      </p>
    </slot>
  </div>
  <div class="popup__intro popup__about" v-if="showModalIntro">
    <slot>
      <p class="popup__about-text popup__intro-text">
        labiblio.tech est la bibliothèque ouverte d’hérétique. Localisée à
        Paris, elle rassemble des livres qui traitent du numérique, de ses
        obsessions, de ses critiques et de ses alternatives possibles.
        
        <br><br>Notre envie : permettre à tous d’approfondir les pensées qui nous animent et
        de découvrir les auteurs qui nous inspirent.
        <br><br>Envie d’emprunter un livre ? Envoyez-nous un mail à <a href="mailto:bonjour@heretique.fr" class="popup__intro-text"><b>bonjour@heretique.fr</b></a> et prenons rendez-vous pour
        vous le transmettre !
      </p>
      <a
        @click="showModalIntro = false"
        class="popup__about-icon popup__intro-close"
        icon="times"
      >Accéder à labiblio.tech <fa icon="arrow-right" /></a>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "TopBar",
  data() {
    return {
      showModalAbout: false,
      showModalIntro: true,
    };
  },
  created(){
    if(this.$route.name == 'BiblioPopup'){
      this.showModalIntro = false
    }
  },
  methods: {
    displayAboutpopup() {
      this.showModalAbout = true;
    },
    emitDisplayAboutPopup: function () {
      this.$root?.$emit("displayAboutPopup");
    },
  },
});
</script>

<style scoped>
.top__bar {
  display: table;
  flex-flow: row;
  background-color: #fae3d3;
  height: fit-content;
  width: 85vw;
}

.top__bar-left {
  background-color: #fae3d3;
  border: black solid 2px;
  width: 35vw;
  border-left: none;
  border-top: none;
  height: 5vh;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: black;
}

.popup__about-text {
  word-wrap: normal;
}

.top__bar-right {
  background-color: #fae3d3;
  border: black solid 2px;
  width: 25vw;
  border-left: none;
  border-right: none;
  border-top: none;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: black;
}

.popup__link {
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.top__bar-center {
  background-color: #fae3d3;
  border: black solid 2px;
  width: 50vw;
  border-left: none;
  border-top: none;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: table-cell;
  vertical-align: middle;
}

.top__bar-text {
  font-family: "Kanit", sans-serif;
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
}

.text__strong {
  font-weight: 600;
}

.top__bar-link {
  text-decoration: none;
  color: black;
}

.popup__about {
  position: absolute;
  height: fit-content;
  background-color: #f9e3d2;
  border: black solid 2px;
  padding: 2rem;
  top: 10%;
  align-self: center;
  width: fit-content;
  z-index: 99;
  font-family: Kanit;
  font-weight: 400;
  max-width: 70vw;
  display: flex;
  flex-flow: column;
}


.popup__intro{
  top: 25%;
}

.popup__intro-close{
  font-size: 1.2rem;
  font-weight: bolder;
}

.popup__about-icon {
  align-self: flex-end;
  cursor: pointer;
}

.popup__about-text {
  margin: 1rem;
  font-size: 1.2rem;
}


.popup__intro-text{
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}

.popup__about-title {
  font-size: 2rem;
}

@media screen and (max-width: 960px) {
  .top__bar {
    width: 100vw;
    display: table;
  }

  .popup__about-title {
    font-size: 1.4rem;
  }



  .popup__about-text {
    font-size: 1rem;
  }

  .popup__about {
    max-width: 70%;
    width: 70%;
  }

  .top__bar-left {
    width: 50vw;
    border-top-style: solid;
    display: table-cell;
  }

  .top__bar-center {
    width: 50vw;
    border-top-style: solid;
    display: table-cell;
    justify-content: center;
  }

  .top__bar-right {
    width: 50vw;
    border-top-style: solid;
    display: table-cell;
    justify-content: center;
  }

  .top__bar-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 460px) {
  .top__bar {
    width: 100vw;
    display: table;
  }

  .top__bar-text {
    font-size: 1rem;
  }

  .popup__intro{
  margin-top: 15%;
}

  .top__bar-text {
    font-size: 0.8rem;
  }

  .popup__about {
    padding: 0.5rem;
    max-width: 90%;
    width: 90%;
    top: 1%;
  }

  .popup__intro-text{
    font-size: 1rem;
  }
}
</style>
