<template>
  <div class="biblio__all" v-if="preloading === false">
    <a
      v-for="i in items"
      v-bind:key="i.id"
      class="biblio__item opacity"
      @click="displayPopup(i.id)"
      :class="{
        'heterodoxie-color-1': i.couleur == 'heterodoxie-1',
        'heterodoxie-color-2': i.couleur == 'heterodoxie-2',
        'heterodoxie-color-3': i.couleur == 'heterodoxie-3',
        'heresie-color-1': i.couleur == 'heresie-1',
        'heresie-color-2': i.couleur == 'heresie-2',
        'heresie-color-3': i.couleur == 'heresie-3',
        'orthodoxie-color-1': i.couleur == 'orthodoxie-1',
        'orthodoxie-color-2': i.couleur == 'orthodoxie-2',
        'orthodoxie-color-3': i.couleur == 'orthodoxie-3',
      }"
    >
      <div class="biblio__text">
        <h3 class="biblio__title">{{ i.titre }}</h3>
        <p class="biblio__author">{{ i.auteur }}</p>
      </div>
    </a>
    <div class="popup__about" v-if="showModal" :class="{
        'heterodoxie-color-1': popupContent.color == 'heterodoxie-1',
        'heterodoxie-color-2': popupContent.color == 'heterodoxie-2',
        'heterodoxie-color-3': popupContent.color == 'heterodoxie-3',
        'heresie-color-1': popupContent.color == 'heresie-1',
        'heresie-color-2': popupContent.color == 'heresie-2',
        'heresie-color-3': popupContent.color == 'heresie-3',
        'orthodoxie-color-1': popupContent.color == 'orthodoxie-1',
        'orthodoxie-color-2': popupContent.color == 'orthodoxie-2',
        'orthodoxie-color-3': popupContent.color == 'orthodoxie-3',
      }">
      <slot>
        <div class="popup__header">
          <fa @click="shareContent(popupContent.book_url)" class="popup__content-icon" icon="share" />
          <fa @click="closePopup()" class="popup__content-icon" icon="times" />
        </div>
        
        <h2 class="popup__content-title">{{ popupContent.title }}</h2>
        <p class="popup__content-author">{{ popupContent.author }}</p>
        <p class="popup__content-content">{{ popupContent.content }}</p>
        <div class="popup__content-footer">
            <a :href="`${ popupContent.link }`" target="_blank" class="popup__content-author"><fa icon="link" />&nbsp;En savoir +</a>
            <p class="popup__content-author">{{ popupContent.category }}</p>

            </div>
        
      </slot>
    </div>
    
  </div>
  
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  
  data() {
    return {
      preloading: true,
      items: [
        {
          id: String,
          titre: String,
          auteur: String,
          disponibilité: String,
          catégorie: String,
          couleur: String,
          link: String,
          contenu: String,
          book_url: String
        },
      ],
      showModal: false,
      displayPopupIntro: true,
      popupContent: {
        title: String,
        author: String,
        content: String,
        category: String,
        color: String,
        link: String,
        book_url: String
      },
    };
  },
  async created() {
    await this.axios
      .get("https://sheetdb.io/api/v1/7it5jspo2ssq7"
      )
      .then((response) => (this.items = response.data));
      this.preloading = false
      if(this.$route.params.id){
        var book_url = this.items.find(e => e.book_url.toString() == this.$route.params.id)
        if(book_url){
          this.displayPopup(Number(book_url.id))
        }
        
    }
  },
  methods: {
    displayPopup(n: number) {
      this.popupContent.title = this.items[n - 1].titre;
      this.popupContent.author = this.items[n - 1].auteur;
      this.popupContent.content = this.items[n - 1].contenu;
      this.popupContent.category = this.items[n - 1].catégorie;
      this.popupContent.color = this.items[n - 1].couleur;
      this.popupContent.link = this.items[n - 1].link;
      this.popupContent.book_url = this.items[n - 1].book_url;
      this.showModal = true;
    },
    closePopup() {
      this.showModal = false;
    },
    shareContent(link: string){
      this.$router.push(`https://labiblio.tech/` + link)
    }
  },
});
</script>

<style scoped>

    
.biblio__all {
  width: 85vw;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 -2px 0 0;
}

.biblio__loading{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.biblio__loading-gif{
  width: 3%;
}

.popup__about {
  position: fixed;
  align-self: center;
  justify-self: center;
  background-color: #f9e3d2;
  border: black solid 2px;
  padding: 3rem;
  z-index: 200;
  font-family: Kanit;
  font-weight: 400;
  width: 50vw;
  max-width: 50vw;
  left: 30%;
  height: max-content;
  display: flex;
  flex-flow: column;
  overflow-wrap: anywhere;
  justify-content: center;
margin: auto;
align-items: center;
}

.popup__content-footer{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    font-size: 1.2rem;
}

.popup__header{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popup__content-content{
    white-space: normal;
    overflow-wrap: anywhere;
    position: relative;
    word-wrap: normal;
    height: fit-content;
    text-align: justify;
    white-space: normal;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
}

.popup__content-icon {
  align-self: flex-end;
  cursor: pointer;
  font-size: 2rem;
}

.popup__about-text {
  margin: 1rem;
  word-wrap:normal;
}

.biblio__title{
    font-weight: 600;
    font-size: 3vh;
    margin: 12rem 0;
}

.popup__content-title{
    font-family: Kanit;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    font-size: 3rem;
    white-space: break-spaces;
}

.popup__content-author{
    font-family: Kanit;
    font-weight: 400;
    text-align: center;
    font-size: 1.6rem;
    text-decoration: none;
    color: black;
}

.biblio__item {
  text-decoration: none;
  color: black;
  font-family: "Kanit", sans-serif;
  height: fit-content;
  min-height: 60%;
  max-height: 95%;
  height: fit-content;
  display: flex;
  justify-content: center;
  border-bottom-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
  width: max-content;
}

.biblio__text {
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  margin: 1rem;
}

.heresie-color-1 {
  background-color: #f28482;
}

.heresie-color-2 {
  background-color: #f4a7a3;
}


.heresie-color-3 {
  background-color: #f5cac3;
}


.orthodoxie-color-1 {
  background-color: #93b4ad;
}

.orthodoxie-color-2 {
  background-color: #78a59c;
}

.orthodoxie-color-3 {
  background-color: #5f958c;
}

.heterodoxie-color-1 {
  background-color: #ffee93;
}


.heterodoxie-color-2 {
  background-color: #f6bd60;
}

.heterodoxie-color-3 {
  background-color: #d99830;
}

.opacity:hover{
    opacity: 0.8;
}

@media screen and (max-width: 960px) {
  .popup__about{
      width: 100vw;
      max-width: 100vw;
      top: 50%;
      left: 0;
      padding: 2rem;
      height: fit-content;
      right: 0;
      bottom: 50%;
      overflow-y: auto;
  }

  .popup__content-text{
      margin: 0;
  }


  .popup__content-title{
      font-size: 2rem;
  }
  .popup__content-author{
      font-size: 1.7rem;
  }

  .popup__content-content{
      font-size: 1.3rem;
      margin-top: 1rem;
  }

  .biblio__title{
    font-size: 1.4rem;
    margin: 8rem 0.5rem;
}

      .popup__about{
      width: 90vw;
      max-width: 90vw;
      left: 0;
      padding: 1rem;
      right: 0;
  }

  .biblio__title{
    margin: 20rem 0.2rem;
  }

  .popup__content-text{
      margin: 0;
      width: 100%;
  }

  .popup__content-title{
      font-size: 1.5rem;
  }
  .popup__content-author{
      font-size: 1.2rem;
  }

  .popup__content-content{
      font-size: 1rem;
      margin-top: 1rem;
  }
  }

  @media screen and (max-width: 460px) {
    .popup__about{
      height: fit-content;
    }


    .biblio__title{
      margin: 15vh 0.2rem;
      word-wrap: normal;
      word-break: normal;
      font-size: 1.1rem;
    }

    .biblio__loading-gif{
      width: 10%;
    }

    .biblio__author{
      font-size: 1rem;
      margin: 0 0.2rem;
    }

    .biblio__item{
      padding: 0.5rem;
      width: fit-content;
    }

    .popup__content-content{
      font-size: 0.8rem;
    }

    .popup__content-title{
      font-size: 1.2rem;
    }

    .popup__content-author{
      font-size: 1.1rem;
    }
  }

  
  </style>