import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Biblio from '../views/Biblio.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Biblio',
    component: Biblio,
    meta: {
      title: 'Bienvenue dans labiblio.tech !'
    }
  },
  {
    path: '/:id',
    name: 'BiblioPopup',
    component: Biblio,
    meta: {
      title: 'Bienvenue dans labiblio.tech !'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + '';
  }
});

export default router