<template>
  <div class="nav__logo">
    <h1 class="nav__logo-title">
      labiblio.<span class="nav__logo-title-span">tech</span>
    </h1>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Logo extends Vue {}
</script>

<style scoped>
.nav__logo {
  height: 100vh;
  width: min-content;
  background-color: #fae3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: black solid 2px;
  border-style: none;
  border-right-style: solid;
  max-width: 15vw;
  text-decoration: none;
}

.nav__logo-title {
  transform: rotate(-90deg);
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-size: 8vw;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.nav__logo-title-span {
  font-family: 'DotGothic16', sans-serif;
  font-weight: 700;
  font-size: 7vw;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .nav__logo {
    height: min-content;
    width: 100vw;
    max-width: 100vw;
    border: none;
    justify-content: center;
    align-items: center;
  }

  .nav__logo-title {
    transform: rotate(0deg);
    font-weight: 700;
    font-size: 3.5rem;
    width: 100vw;
    text-align: center;
  }

  .nav__logo-title-span {
  font-family: 'DotGothic16', sans-serif;
  font-weight: 700;
  font-size: 3rem;
  margin: 0;
  padding: 0;
}
}

@media screen and (max-width: 460px) {
  .nav__logo-title{
    font-size: 2.5rem;
  }

  .nav__logo-title-span{
    font-size: 2.3rem;
  }
}
</style>
