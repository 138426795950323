
import { defineComponent } from "vue";
export default defineComponent({
  
  data() {
    return {
      preloading: true,
      items: [
        {
          id: String,
          titre: String,
          auteur: String,
          disponibilité: String,
          catégorie: String,
          couleur: String,
          link: String,
          contenu: String,
          book_url: String
        },
      ],
      showModal: false,
      displayPopupIntro: true,
      popupContent: {
        title: String,
        author: String,
        content: String,
        category: String,
        color: String,
        link: String,
        book_url: String
      },
    };
  },
  async created() {
    await this.axios
      .get("https://sheetdb.io/api/v1/7it5jspo2ssq7"
      )
      .then((response) => (this.items = response.data));
      this.preloading = false
      if(this.$route.params.id){
        var book_url = this.items.find(e => e.book_url.toString() == this.$route.params.id)
        if(book_url){
          this.displayPopup(Number(book_url.id))
        }
        
    }
  },
  methods: {
    displayPopup(n: number) {
      this.popupContent.title = this.items[n - 1].titre;
      this.popupContent.author = this.items[n - 1].auteur;
      this.popupContent.content = this.items[n - 1].contenu;
      this.popupContent.category = this.items[n - 1].catégorie;
      this.popupContent.color = this.items[n - 1].couleur;
      this.popupContent.link = this.items[n - 1].link;
      this.popupContent.book_url = this.items[n - 1].book_url;
      this.showModal = true;
    },
    closePopup() {
      this.showModal = false;
    },
    shareContent(link: string){
      this.$router.push(`https://labiblio.tech/` + link)
    }
  },
});
