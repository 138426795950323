<template>
  <div id="biblio">
    <TopBar />
    <BiblioAll class="scroll" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TopBar from "@/components/TopBar.vue";
import BiblioAll from "@/components/BiblioAll.vue";
@Options({
  components: {
    TopBar,
    BiblioAll,
  },
})
export default class Biblio extends Vue {}
</script>

<style scoped>
#biblio {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: space-between;
  background-color: #fae3d3;
  height: 100vh;
}

CategorySelector {
  z-index: 100;
}

BiblioAll {
  z-index: 10;
}

.scroll {
  width: 85vw;
  overflow: auto;
  white-space: nowrap;
}

.scroll::-webkit-scrollbar{
  height: 2vh;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: black; /* color of the scroll thumb */
  border-radius: 50px; /* roundness of the scroll thumb */
}

TopBar {
  z-index: 100;
}



@media screen and (max-width: 960px) {
  .scroll{
    white-space: pre-wrap;
    width: 100vw;
  }

}
</style>
