
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "TopBar",
  data() {
    return {
      showModalAbout: false,
      showModalIntro: true,
    };
  },
  created(){
    if(this.$route.name == 'BiblioPopup'){
      this.showModalIntro = false
    }
  },
  methods: {
    displayAboutpopup() {
      this.showModalAbout = true;
    },
    emitDisplayAboutPopup: function () {
      this.$root?.$emit("displayAboutPopup");
    },
  },
});
