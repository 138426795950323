<template id="app">

<Logo/>
  <router-view/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Logo from '@/components/Logo.vue'
@Options({
  components:{
    Logo
  }
})
export default class App extends Vue {

}


</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Kanit:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

  *{
    padding: 0;
    margin: 0;
  }

  html

  body{
       background-color: #f9e3d2;
  }

  #app{
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: #f9e3d2;
  }

  @media screen and (max-width: 960px) {
    #app{
      flex-flow: column;
    }
  }
</style>
