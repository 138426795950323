
import { Options, Vue } from "vue-class-component";
import TopBar from "@/components/TopBar.vue";
import BiblioAll from "@/components/BiblioAll.vue";
@Options({
  components: {
    TopBar,
    BiblioAll,
  },
})
export default class Biblio extends Vue {}
